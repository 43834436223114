<template>
	<div id="main">
		<div id="nav">
			<van-row>
				<van-col span="8">
				</van-col>
				<van-col span="8">
					用户状态查询
				</van-col>
				<van-col span="8">
				</van-col>
			</van-row>
		</div>
		<div>
			<van-row>
				<van-col span="24">
					<van-form @submit="onSubmit">
						<van-field v-model="URL" name="URL" label="URL" placeholder="请输入订阅地址!" :rules="[{ required: true, message: '请输入订阅地址！' }]" />
						<div style="margin: 16px;">
							<van-button round block type="info" native-type="submit">提交</van-button>
						</div>
					</van-form>
				</van-col>
			</van-row>
		</div>
		<div>
			<van-row>
				<van-col span="24">
					<van-notice-bar left-icon="info-o">{{msg}}
					</van-notice-bar>
				</van-col>
			</van-row>
		</div>
		<div v-show="showRentPrise">
			<van-row>
				<van-col span="24">
					<van-collapse v-model="activeNames">
						<van-collapse-item :title="index" v-for="(item,index) in items" :key="index" :name="index">
							<p v-html="item"></p>
						</van-collapse-item>
					</van-collapse>
				</van-col>
			</van-row>
		</div>
		<van-tabbar v-model="active" @change="onChange">
			<van-tabbar-item icon="search" url="/">查询</van-tabbar-item>
			<van-tabbar-item icon="home-o">列表</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeNames: ["1"],
				msg: '',
				showRentPrise: false,
				items: {},
				URL: '',
				active: 0
			};
		},
		methods: {
			onChange(index) {
				const routerArray = [
					"/",
					"/Services",
				];
				this.$router.push(routerArray[index])
			},
			onSubmit(values) {
				console.log("123123")
				this.showRentPrise = false;
				this.msg = '';
				this.$axios.get("/code", {
					params: {
						"code": values.URL,
					}
				}).then(res => {
					console.log(res.data.msg);
					this.items = res.data.datas;
					this.msg = res.data.msg;
					this.$dialog({
						message: res.data.msg
					});
					if (res.data.offYear == 1) {
						//禁止
						this.data = res.data.datas;
						this.showRentPrise = true;
					} else if (res.data.offYear == 2) {
						//存在风险
						this.data = res.data.datas;
						this.showRentPrise = true;
					}
				});
			},
		},
	};
</script>

<style>
	.custom-title {
		margin-right: 4px;
		vertical-align: middle;
	}

	#home {
		text-align: center;
	}

	.search-icon {
		font-size: 16px;
		line-height: inherit;
	}
</style>
